import { Grid } from '@hexa-ui/components';
import { Typography } from '@hexa-ui/components2';
import {
  CountryData,
  DocumentTitle,
  useAuthenticationService,
  useCountryService,
  useUserMetadata,
  Vendor,
} from 'admin-portal-shared-services';
import { useAuthContext, useEnvContext } from 'contexts';
import { useGetBTGuideURL } from 'hooks';
import { useGetApps } from 'hooks/useGetApps';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { getCurrentLogo } from 'utils/logo-utils/logoUtils';
import { HomeToolCards } from './components/HomeToolCards';
import LatestNotifications from './components/LatestNotifications';
import { QuickLinks } from './components/QuickLinks';
import { Select, SelectOptions } from './components/Select';
import {
  Container,
  CountryVendorContainer,
  GreetingsCard,
  GreetingsContainer,
  GreetingsTextContainer,
  LatestNotificationsContainer,
  QuickLinksContainer,
} from './Dashboard.styles';

type Layout = 'error' | 'loading' | 'noTools' | 'singleTool' | 'default';

export function Dashboard() {
  const { formatMessage, locale } = useIntl();
  const authenticationService = useAuthenticationService();
  const { given_name: firstName } = authenticationService.getUserFullNameB2C();
  const {
    data,
    isLoading: isAppsLoading,
    isSuccess: isAppsSuccess,
    isError: isAppsError,
  } = useGetApps();
  const [allowedToolsLength, setAllowedToolsLength] = useState(0);
  const { appsWithScope } = useAuthContext();
  const envContextValues = useEnvContext();
  const isDtc = envContextValues?.defaultLogo === 'tada';

  const logo = getCurrentLogo(envContextValues?.defaultLogo);

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  let layout: Layout = 'default';

  if (isAppsError) {
    layout = 'error';
  } else if (allowedToolsLength === 0) {
    layout = 'noTools';
  } else if (isAppsLoading) {
    layout = 'loading';
  } else if (allowedToolsLength === 1) {
    layout = 'singleTool';
  }
  const { data: userData } = useUserMetadata();
  const countryService = useCountryService();
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
  const [selectedVendor, setSelectedVendor] = useState<string | undefined>();
  const [vendorsOptions, setVendorsOptions] = useState<SelectOptions[]>([]);

  const { url, hasError, isLoading } = useGetBTGuideURL({
    country: selectedCountry,
    vendorId: selectedVendor,
  });

  const vendors = useMemo(() => {
    return userData?.vendors || [];
  }, [userData]);

  const countries = useMemo(() => {
    if (countryService.data) {
      return (countryService.data as CountryData[]).reduce(
        (acc, countryData: CountryData) => ({
          ...acc,
          [countryData.code]: countryData,
        }),
        {},
      );
    }
    return undefined;
  }, [countryService.data]);

  const getUniqueVendorCountries = (vendors: Vendor[]): string[] => {
    const countries = vendors.map((vendor) => vendor.country);
    return Array.from(new Set(countries));
  };

  const countriesOptions = getUniqueVendorCountries(vendors)
    .map((country) => ({
      value: country,
      label:
        countries?.[country]?.name?.[locale?.split('-')[0]] ||
        countries?.[country]?.name?.['en'],
    }))
    .sort((a, b) => a.label?.localeCompare(b.label));

  const onChangeCountry = useCallback(
    (country: string) => {
      setSelectedCountry(country);
      const vendorsByCountry = vendors
        ?.filter((vendor) => vendor.country === country)
        .sort((a, b) => a.displayName?.localeCompare(b.displayName))
        .map((vendor) => ({
          value: vendor.id,
          label: vendor.displayName,
        }));
      setVendorsOptions(vendorsByCountry);
      setSelectedVendor(vendorsByCountry?.[0]?.value);
    },
    [vendors],
  );

  useEffect(() => {
    if (countriesOptions.length > 0 && !selectedCountry) {
      onChangeCountry(countriesOptions[0].value);
    }
  }, [countriesOptions, onChangeCountry, selectedCountry]);

  const hasAppAccess = (appIdentifier: string) => {
    return appsWithScope.some((app) => app.id === appIdentifier);
  };

  const hasCountryAndVendorSelect =
    userData?.vendors?.length > 0 && hasAppAccess('PARTNER-REPORTS');

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', padding: '24px 0px' }}
    >
      <DocumentTitle>Home</DocumentTitle>
      <Grid.Container type="fluid" style={{ width: '1328px', rowGap: '16px' }}>
        <Grid.Item style={{ width: '100%', padding: 0 }}>
          <GreetingsCard elevated="minimal" border="medium">
            <GreetingsContainer>
              <img src={logo.icon} width="56px" alt={logo.alt} />
              <GreetingsTextContainer>
                <Typography.Header variant="h4" style={{ margin: '0px' }}>
                  {formatMessage(
                    { id: 'Home.greetings' },
                    { name: capitalizeFirstLetter(firstName) },
                  )}
                </Typography.Header>
                <Typography.Body variant="large" style={{ margin: '0px' }}>
                  {formatMessage({
                    id: `${isDtc ? 'Home.dtcDescription' : 'Home.description'}`,
                  })}
                </Typography.Body>
              </GreetingsTextContainer>
            </GreetingsContainer>
            {hasCountryAndVendorSelect && (
              <CountryVendorContainer>
                <Select
                  options={countriesOptions}
                  value={selectedCountry}
                  onChange={onChangeCountry}
                  variant="country"
                />
                <Select
                  options={vendorsOptions}
                  value={selectedVendor}
                  onChange={setSelectedVendor}
                  variant="vendor"
                />
              </CountryVendorContainer>
            )}
          </GreetingsCard>
        </Grid.Item>

        <Container
          layout={layout}
          data-testid="dashboard-container"
          data-layout={layout}
        >
          <HomeToolCards
            apps={data?.data?.content}
            isSuccess={isAppsSuccess}
            isLoading={isAppsLoading}
            setAllowedToolsLength={setAllowedToolsLength}
            btGuideUrl={hasError || isLoading ? '' : url}
          />
          <LatestNotificationsContainer>
            <LatestNotifications />
          </LatestNotificationsContainer>

          <QuickLinksContainer>
            <QuickLinks />
          </QuickLinksContainer>
        </Container>
      </Grid.Container>
    </div>
  );
}

export default Dashboard;

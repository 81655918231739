import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { analyticsBusinessService } from 'services/AnalyticsBusinessService';

export type GetBTGuideUrlOptions = {
  vendorId: string;
  country: string;
};

export const useGetBTGuideURL = ({
  vendorId,
  country,
}: GetBTGuideUrlOptions) => {
  const { locale } = useIntl();
  const [url, setUrl] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const fetchUrl = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      setHasError(false);

      const response = await analyticsBusinessService.getBTGuideURL({
        vendorId: vendorId,
        country: country,
        locale,
      });
      setUrl(response?.data?.url || '');
    } catch {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [locale, country, vendorId]);

  useEffect(() => {
    if (!vendorId || !country) return;
    fetchUrl();
  }, [fetchUrl, vendorId, country]);

  return { url, isLoading, hasError };
};
